import React from 'react'
import BoxCreateEvents from '../box-create-events/box-create-events'
import CardCarousel from '../card/card-carousel-my-events'
import CardPanel from '../card/panel'
import { BoxDetail } from '../box'

export default props => (
  <div>
    <div className="__header-box" id="owl-bg-red">
      <BoxCreateEvents
        title={props.locale.CREATE_NEW_EVENTS}
        textChoose={props.locale.CHOOSE}
        onChoose={props.onChooseEvent}
        data={props.dataPackageCreateNewEvents}
      />
    </div>
    <BoxDetail>
      <CardPanel
        title={props.locale.MY_EVENT}
        onSeeAll={props.onGoSellAllOwnerEvent}
        locale={props.locale}
      >
        {props.data.length > 0 && (
          <CardCarousel
            className="is-card-slider"
            data={props.data}
            dataMobile={props.dataMobile}
            onEdit={id => props.onEdit(id)}
            onManage={(id, payload) => props.onManage(id, payload)}
            locale={props.locale}
          />
        )}
      </CardPanel>
    </BoxDetail>
  </div>
)
