import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import Button from '../form/buttons'
import Modal from 'react-modal'
import IconClose from '../../images/ic_close.svg'
import QRCode from 'qrcode.react'
import MediaQuery from 'react-responsive'

const ColumnHeader = styled.div`
  opacity: 0.87;
  font-family: RSU-Bold;
  font-size: 24px;
  color: #3f3131;
`

const Line = styled.hr`
  border: 0;
  height: 1px;
  background: #f0f0f1;
  margin: ${({ margin = 20 }) => `${margin}px 0`};
`

const LineColumnTable = styled(Line)`
  background: #3f3131;
  margin: 12px 0;
`

const ColumnContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  div:nth-child(1) {
    flex: 2;
  }
  div:not(:first-child) {
    flex: 1;
  }
`

const RowContainer = styled.div`
  display: flex;
  text-align: center;

  div:nth-child(1) {
    flex: 2;
    text-align: left;
  }
  div:not(:first-child) {
    flex: 1;
  }
`

const RowValue = styled.div`
  opacity: 0.87;
  font-size: 16px;
  color: #3f3131;
  letter-spacing: 0.5px;
  line-height: 28px;
`

const RowTotal = styled(RowContainer)`
  margin-top: 32px;

  div:nth-child(4) {
    font-family: RSU-Bold;
    font-size: 20px;
    color: #615251;
    letter-spacing: 0.25px;
  }
`

const RowVat = styled(RowContainer)`
  div:nth-child(4) {
    font-size: 20px;
    color: #6b5d5b;
    letter-spacing: 0.25px;
  }
`

const RowGrandTotal = styled(RowContainer)`
  div {
    opacity: 0.87;
    font-family: RSU-Bold;
    font-size: 24px;
    color: #3f3131;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ModalTotal = styled.div`
  opacity: 0.87;
  font-family: RSU-Bold;
  font-size: 34px;
  color: #3f3131;
  letter-spacing: 0.25px;
  text-align: center;
`

const ModalHowtopay = styled.div`
  opacity: 0.87;
  font-family: RSU-Bold;
  font-size: 20px;
  color: #3f3131;
  letter-spacing: 0.25px;
`

const ModalPayStep = styled.div`
  opacity: 0.87;
  font-family: RSU;
  font-size: 20px;
  color: #938a87;
  letter-spacing: 0.25px;
`

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`

export default class ConfirmTicket extends Component {
  state = {
    checked: false,
    showModal: false
  }
  formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  render() {
    const { checked } = this.state
    const { onBack } = this.props
    return (
      <Fragment>
        <div className="club__header">
          <hr className="club__line" />
          <h3 style={this.styleFont1}>{this.props.locale['ORDER_SUMMARY']}</h3>
        </div>
        <div className="club__card" style={{ minHeight: 244, marginTop: 20 }}>
          <div style={{ marginBottom: 32 }}>
            <ColumnHeader>
              {this.props.locale['REVIEW_ORDER_SUMMARY']}
            </ColumnHeader>
            <Line />

            <div className="flex flex-column-mobile">
              <div className="flex-auto mg-r-40">
                <p className="information__label">
                  {this.props.locale['NAME_AND_FAMILY_NAME']}
                </p>
                <p className="information__value">
                  {this.props.invitreeRegister.name ? this.props.invitreeRegister.name : this.props.clubRegister.user_info.firstname + '  ' + this.props.clubRegister.user_info.lastname}
                </p>
              </div>
              <div className="flex-auto">
                <p className="information__label">
                  {this.props.locale['E_MAIL']}
                </p>
                <p className="information__value">{this.props.invitreeRegister.email}</p>
              </div>
            </div>
            <div className="flex flex-column-mobile">
              <div className="flex-auto mg-r-40">
                <p className="information__label">
                  {this.props.locale['PAYMENT']}
                </p>
                <p className="information__value">
                  {(this.props.paymentMethod === 'qrcode') ? this.props.locale['PAYMENT_BY_QR_CODE'] : this.props.locale['PATMENT_BY_CREDIT_CARD']}
                </p>
              </div>
            </div>
            <div style={{ width: '100%', overflowX: 'scroll' }}>
              <div style={{ minWidth: 700 }}>
                <LineColumnTable />
                <ColumnContainer>
                  <div>{this.props.locale['ITEM']}</div>
                  <div>{this.props.locale['PRICE']}</div>
                  <div>{this.props.locale['FEE']}</div>
                  <div>{this.props.locale['QUANTITY']}</div>
                  <div>{this.props.locale['SUBTOTAL']}</div>
                </ColumnContainer>
                <LineColumnTable />
                {/* Package Section */}
                    <RowContainer key={`Package1`}>
                      <RowValue>
                        { this.props.lang === 'EN' ? this.props.buyEventInfo.description_en : this.props.buyEventInfo.description_th }
                      </RowValue>
                      <RowValue>
                        ฿{this.formatNumber(parseFloat(this.props.buyEventInfo.price).toFixed(2))}.00
                      </RowValue>
                      <RowValue>฿0.00</RowValue>
                      <RowValue>
                        1
                      </RowValue>
                      <RowValue>฿{this.formatNumber(parseFloat(this.props.buyEventInfo.price).toFixed(2))}</RowValue>
                    </RowContainer>
                {/* Package Section */}
                <RowTotal>
                  <RowValue />
                  <RowValue />
                  <RowValue />
                  <RowValue>{this.props.locale['TOTAL']}</RowValue>
                  <RowValue>฿{this.formatNumber(parseFloat(this.props.buyEventInfo.price).toFixed(2))}</RowValue>
                </RowTotal>
                <Line margin={16} />
                <RowVat>
                  <RowValue />
                  <RowValue />
                  <RowValue />
                  <RowValue>{this.props.locale['SERVICE_CHARGE']}</RowValue>
                  <RowValue>฿0.00</RowValue>
                </RowVat>
                <Line margin={16} />
                {/* Wait for VAT implementation */}
                {/* <RowVat>
                  <RowValue />
                  <RowValue />
                  <RowValue />
                  <RowValue>{this.props.locale['VAT']}</RowValue>
                  <RowValue>฿{this.formatNumber((parseFloat(this.props.buyEventInfo.price) * 7 / 100).toFixed(2))}</RowValue>
                </RowVat> */}
                <Line margin={16} />
                <RowGrandTotal>
                  <RowValue />
                  <RowValue />
                  <RowValue />
                  <RowValue>{this.props.locale['GRAND_TOTAL']}</RowValue>
                  <RowValue>฿{this.formatNumber(parseFloat(this.props.buyEventInfo.price).toFixed(2))}</RowValue>
                </RowGrandTotal>
                <LineColumnTable />
              </div>
            </div>

            <div style={{ marginTop: 32 }}>
              <label
                className={`checkbox-label flex-auto ${checked === 'retired' ? 'checkbox-label--disabled' : ''
                  }`}
                style={{ fontSize: 20 }}
              >
                {this.props.locale['WARNING_REFUND_CONDITION']}
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => {
                    let tempAmount = ((parseFloat(this.props.buyEventInfo.price) * 107 / 100).toFixed(2))
                    this.props.getAmount(tempAmount)
                    this.setState({ checked: !checked })
                    }}
                />
                <span
                  className={`checkmark ${checked === 'retired' ? 'checkmark--disabled' : ''
                    }`}
                />
              </label>
            </div>

            <ButtonContainer>
              <Button.Button8
                style={{
                  padding: 0,
                  width: 160,
                  height: 48,
                  margin: '48px 12px 0 12px'
                }}
                onClick={this.props.toggleOrderSummary}
              >
                {this.props.locale['BACK_TO_EDIT']}
              </Button.Button8>
              <Button.Button7
                style={{
                  padding: 0,
                  width: 160,
                  height: 48,
                  margin: '48px 12px 0 12px'
                }}
                onClick={() => {
                  if (this.state.checked) {
                    this.props.buyEvent(
                        this.props.buyEventInfo.package_id,
                        this.props.buyEventInfo.typeBuy,
                        this.props.buyEventInfo.eventID,
                        this.props.buyEventInfo.joinType,
                    )
                  }
                }}
                disabled={!this.state.checked}
              >
                {this.props.locale['CONFIRM']}
              </Button.Button7>
            </ButtonContainer>
          </div>
        </div>
        <Modal
          isOpen={this.state.showModal}
          onRequestClose={() => this.setState({ showModal: false })}
          className="information__modal"
          overlayClassName="information__modal__overlay"
        >
          <div style={{ fontFamily: 'RSU' }}>
            <img
              src={IconClose}
              alt=""
              className="information__modal__close"
              onClick={() => this.setState({ showModal: false })}
            />

            <ModalTotal>
              {this.props.locale['TOTAL_PRICE']} 1,500.45 บาท
            </ModalTotal>
            <div style={{ textAlign: 'left', width: '100%', marginTop: 20 }}>
              <ModalHowtopay>
                {this.props.locale['HOW_TO_PAY_WITH']} QR Code
              </ModalHowtopay>
              <ModalPayStep>
                <div>1. {this.props.locale['SCAN_AND_PAY_WITH_BANK']}</div>
                <div>2. {this.props.locale['SAVE_AND_PAY_LATER']}</div>
              </ModalPayStep>

              <div style={{ textAlign: 'center' }}>
                <QRCode
                  value="00020101021230810016A00000067701011201150107536000315080214KB1481689188220320API4264036481694216631690016A00000067701011301030040214KB1481689188220420API4264036481694216653037645406100.505802TH6304BBE0"
                  size={200}
                  includeMargin
                />
              </div>
            </div>
            <ModalButtonContainer>
              <Button.Button8
                style={{ padding: 0, marginRight: 6, width: 164 }}
                onClick={() => this.setState({ showModal: false })}
              >
                {this.props.locale['SAVE_QR_CODE']}
              </Button.Button8>
              <Button.Button7
                style={{ padding: 0, marginRight: 6, width: 164 }}
                onClick={() => { }}
              >
                {this.props.locale['COMPLETED']}
              </Button.Button7>
            </ModalButtonContainer>
          </div>
        </Modal>
      </Fragment>
    )
  }
}
