import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Loading from '../components/loading'
import View from '../components/presentation/my-events'
import OrderSummary from '../components/presentation/confirm-package'
import { BoxGray } from '../components/template/box'

import authActions from '../state/auth/actions'
import homeActions from '../state/home/actions'

class MyEventPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buyEventInfo: {},
      isOpenConfirmPackage: false,
      paymentMethod: 'creditcard',
      finalAmount: 0.00
    }
    this.getbuyEventInfo = this.getbuyEventInfo.bind(this)
    this.getAmount = this.getAmount.bind(this)
    this.toggleOrderSummary = this.toggleOrderSummary.bind(this)
  }
  componentDidMount() {
    this.props.checkAuthorization(null, '/', null, {
      onSuccess: () => this.props.onLoad(),
    })
  }

  getbuyEventInfo = (packageInfo) => {
    this.setState({buyEventInfo: packageInfo, isOpenConfirmPackage: true})
  }

  getAmount = (amount) => {
    this.setState({ finalAmount: amount})
  }

  toggleOrderSummary = () => {
    this.setState({ isOpenConfirmPackage: !this.state.isOpenConfirmPackage })
  }

  reArrayCardData(dataList) {
    const newList = []
    if (dataList.length > 0) {
      dataList.forEach(v => {
        newList.push(...v)
      })
    }
    return newList
  }

  onGoSellAllOwnerEvent = () => {
    navigate(`/my-event-all`)
  }

  render() {
    if (!this.props.state.readyPageLogin) {
      return <Loading />
    }
    if (!this.props.state.readyPageMyEvent) {
      return <Loading />
    }
    return (
      <Layout auth={this.props.auth.currentUser !== null}>
        <SEO keywords={[`invitree`]} />
        <BoxGray>
          {!this.state.isOpenConfirmPackage ? (
            <View
            onEdit={this.props.onEditEvent}
            onManage={this.props.onManageEvent}
            onGoSellAllOwnerEvent={this.onGoSellAllOwnerEvent}
            data={this.props.state.listMyEvents || []}
            dataPackageCreateNewEvents={
              this.props.state.listPackageCreateNewEvents || []
            }
            dataMobile={
              this.reArrayCardData(this.props.state.listMyEvents) || []
            }
            onChooseEvent={this.getbuyEventInfo}
            locale={this.props.app.locale[this.props.app.lang]}
          />
          ): (
            <OrderSummary
            invitreeRegister={this.props.auth.currentUser.attributes}
            paymentMethod={this.state.paymentMethod}
            buyEventInfo={this.state.buyEventInfo}
            getAmount={this.getAmount}
            buyEvent={this.props.onChooseEvent}
            finalAmount={this.state.finalAmount}
            toggleOrderSummary={this.toggleOrderSummary}
            locale={this.props.app.locale[this.props.app.lang]}
            lang={this.props.app.lang}
            />
          )}
        </BoxGray>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    app: state.app,
    state: state.home,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      onLoad: homeActions.onLoadMyEvent,
      onChooseEvent: homeActions.onChooseEvent,
      onEditEvent: homeActions.onEditEvent,
      onManageEvent: homeActions.onManageEvent,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyEventPage)
